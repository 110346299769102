import { Sidebar } from 'flowbite-react';
import { NavLink } from 'react-router-dom';

import { SideBarItem, SubMenu, renderSidebarListInterface } from '../../@types';

export const RenderSidebarList = ({
  renderSidebar,
  setSidebarOpen,
}: renderSidebarListInterface) => {
  return (
    <div>
      {renderSidebar.map((item: SideBarItem, index) => {
        return !item?.subPages?.length ? (
          <span
            key={index}
            onClick={() => {
              setSidebarOpen(false);
            }}
          >
            <NavLink
              to={item.path}
              key={item.name}
              className={({ isActive }) =>
                isActive
                  ? 'flex bg-primary-50 dark:bg-primary-600 mt-1 rounded-lg'
                  : 'mt-1 flex activehover'
              }
            >
              <Sidebar.Item
                as='div'
                icon={item.icon}
                className='py-3 hover:bg-primary-50 hover:dark:bg-primary-600'
              >
                <p className='text-sm'>{item.name}</p>
              </Sidebar.Item>
            </NavLink>
          </span>
        ) : (
          <Sidebar.Collapse className=' !text-sm ' label={item.name} icon={item.icon}>
            {item?.subPages?.map((val: SubMenu, index) => {
              return (
                <span
                  key={index}
                  onClick={() => {
                    setSidebarOpen(false);
                  }}
                >
                  <NavLink
                    to={val.subPath}
                    key={val.subName}
                    className={({ isActive }) =>
                      isActive
                        ? 'bg-primary-50 flex mt-1 rounded-lg hover:bg-primary-50'
                        : 'mt-1 flex activehover'
                    }
                  >
                    <Sidebar.Item
                      as='div'
                      icon={val.subIcon}
                      href='#'
                      className='hover:bg-primary-50'
                    >
                      <div className='flex items-center text-sm light:hover:bg-primary-50'>
                        {val.subName}
                      </div>
                    </Sidebar.Item>
                  </NavLink>
                </span>
              );
            })}
          </Sidebar.Collapse>
        );
      })}
    </div>
  );
};
